import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import getGraphQlImageStatic from '../../utils/get-graph-ql-image-static';

const FeaturedContentCard = ({ title, body, icon, callToActionText, callToActionLink }) => {
  const iconUrl = getGraphQlImageStatic(icon);

  const content = (
    <Fragment>
      <img className="featured-content-card__image" src={iconUrl} alt={`${title} icon`} />
      <div className="featured-content-card__content">
        <h3 className="featured-content-card__title">{title}</h3>
        <p className="featured-content-card__body">{body}</p>
        {callToActionText && callToActionLink && (
          <span className="featured-content-card__cta button button--alt button--small">{callToActionText}</span>
        )}
      </div>
    </Fragment>
  );

  if (callToActionLink) {
    return (
      <Link className="featured-content-card" to={callToActionLink}>
        {content}
      </Link>
    );
  }

  return <div className="featured-content-card">{content}</div>;
};

export default FeaturedContentCard;
