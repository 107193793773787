import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import classNames from 'classnames';

import getExcerpt from '../../utils/get-excerpt';
import { getBlogUrl, getTaxonomyUrl } from '../../utils/urls';
import getGraphqlImage from '../../utils/get-graphql-image';
import Button from './link-button';

const BlogPostCard = ({
  title,
  slug,
  date,
  content,
  excerpt,
  image,
  categoryName,
  categorySlug,
  excerptLength,
  ctaText,
  isListing,
}) => {
  const link = `/${slug}`;
  const generatedExceprt = getExcerpt(content, excerpt, excerptLength);
  const imageSizes = getGraphqlImage(image);
  const categoryUrl = getTaxonomyUrl(getBlogUrl(), 'category', categorySlug);

  return (
    <article
      className={classNames('blog-post-card', {
        'blog-post-card--listing': isListing,
        'blog-post-card--has-image': imageSizes,
      })}
    >
      <header className="blog-post-card__header">
        <h2 className="blog-post-card__title">
          <Link className="blog-post-card__title-link" to={link} dangerouslySetInnerHTML={{ __html: title }} />
        </h2>

        {categorySlug && categoryName && (
          <div className="blog-post-card__categories">
            <Link className="blog-post-card__category" to={categoryUrl}>
              {categoryName}
            </Link>
          </div>
        )}
        <time className="blog-post-card__date">{date}</time>
      </header>
      {imageSizes && (
        <div className="blog-post-card__image-wrapper">
          <Link to={link}>
            <Img alt={`${title} image`} className="blog-post-card__image" fluid={imageSizes} />
          </Link>
        </div>
      )}
      {generatedExceprt && (
        <p className="blog-post-card__excerpt" dangerouslySetInnerHTML={{ __html: generatedExceprt }} />
      )}
      <Button className="blog-post-card__cta" slug={link} alt small>
        {ctaText}
      </Button>
    </article>
  );
};

BlogPostCard.defaultProps = {
  excerpt: null,
  content: '',
  image: {
    localFile: {
      childImageSharp: {
        sizes: {
          aspectRatio: 1.7727272727272727,
          base64:
            'data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABvWXYKyCJT//EABkQAQADAQEAAAAAAAAAAAAAAAIAAQQDM//aAAgBAQABBQIMtaeZoTGavps85//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAABBAMAAAAAA...',
          sizes: '(max-width: 780px) 100vw, 780px',
          src: '/static/af8aca7e46297667339d91c232b1a5b4/2be9a/test-post-image.jpg',
          srcSet:
            '/static/af8aca7e46297667339d91c232b1a5b4/e515e/test-post-image.jpg 195w,\n/static/af8aca7e46297667339d91c232b1a5b4/9e99c/test-post-image.jpg 390w,\n/static/af8aca7e46297667339d91c232b1a5b4/2be9a/test-post-image.jpg 780w',
        },
      },
    },
  },
  excerptLength: 134,
  categoryName: null,
  categorySlug: null,
  ctaText: 'Read more',
  isListing: false,
};

BlogPostCard.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  content: PropTypes.string,
  excerpt: PropTypes.string,
  image: PropTypes.shape({}),
  excerptLength: PropTypes.number,
  categoryName: PropTypes.string,
  categorySlug: PropTypes.string,
  ctaText: PropTypes.string,
  isListing: PropTypes.bool,
};

export default BlogPostCard;
