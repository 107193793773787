import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import getGraphqlImage from '../../utils/get-graphql-image';

const FeaturedContentBanner = ({ title, body, backgroundImage, callToActionText, callToActionLink }) => {
  const imageSizes = getGraphqlImage(backgroundImage);

  return (
    <section className="featured-content-banner">
      <div className="featured-content-banner__inner container">
        <div className="featured-content-banner__content-wrapper">
          <div className="featured-content-banner__content">
            <div className="featured-content-banner__content-inner">
              <h2 className="featured-content-banner__title">{title}</h2>
              {body && <div className="featured-content__intro" dangerouslySetInnerHTML={{ __html: body }} />}
              <Link className="button" to={callToActionLink}>
                {callToActionText}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Img className="featured-content-banner__background" fluid={imageSizes} src={imageSizes.src} />
    </section>
  );
};

export default FeaturedContentBanner;
