import React from 'react';
import uniqid from 'uniqid';
import FeaturedContentCard from '../atoms/featured-content-card';

const FeaturedContent = ({ title, icon, intro, items }) => (
  <section className="featured-content">
    <div className="featured-content__inner container">
      <div className="featured-content__header">
        <h2 className="featured-content__title">{title}</h2>
        {intro && <div className="featured-content__intro" dangerouslySetInnerHTML={{ __html: intro }} />}
      </div>
      {items && (
        <div className="featured-content__items">
          {items.map(item => {
            return (
              <div className="featured-content__item" key={uniqid()}>
                <FeaturedContentCard {...item} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  </section>
);

export default FeaturedContent;
