import React from 'react';
import classNames from 'classnames';
import BlogPostCard from '../atoms/blog-post-card';

const LatestPosts = ({ className, title: sectionTitle, items }) => (
  <section className={classNames('latest-posts', className)}>
    <div className="latest-posts__inner container">
      <h2 className="latest-posts__title">{sectionTitle}</h2>
      <div className="latest-posts__items">
        {items.map(({ node }) => {
          const { title, slug, date, categories, featured_media: featuredMedia, excerpt, content, id } = node;
          const category = categories && categories[0];
          const { name: categoryName, slug: categorySlug } = category;
          const imageObj = featuredMedia || undefined;

          return (
            <div className="latest-posts__item" key={id}>
              <BlogPostCard
                title={title}
                slug={slug}
                date={date}
                excerpt={excerpt}
                content={content}
                image={imageObj}
                categoryName={categoryName}
                categorySlug={categorySlug}
              />
            </div>
          );
        })}
      </div>
    </div>
  </section>
);

export default LatestPosts;
