const stripTags = require('striptags');

function getExcerpt(content, excerpt, limit, suffix = '...') {
  let createFrom = excerpt;

  if (!createFrom) {
    createFrom = content;
  }

  if (createFrom) {
    const stripped = stripTags(createFrom);

    if (stripped.length > limit) {
      const truncated = stripped.substring(0, limit);

      return `${truncated}${suffix}`;
    }

    return `${stripped}${suffix}`;
  }

  return null;
}

module.exports = getExcerpt;
